<template>
  <form class="row create-issue-form g-0" @submit="handleCreateForm">
    <div v-loading="loading" class="col">
      <div class="row">
        <div class="col">
          <p>{{ $dictionary.issue_before_create_form_message }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col form-fields">
          <el-input v-model="topic" type="text" :placeholder="$dictionary.issue_create_form_title_placeholder" />
          <form-control-errors :error-bag="errors" field="title"></form-control-errors>
          <el-input v-model="body" type="textarea" :placeholder="$dictionary.issue_create_form_body_placeholder" />
          <form-control-errors :error-bag="errors" field="description"></form-control-errors>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-auto">
          <button class="btn" type="submit">{{$dictionary.issue_create_form_submit_label}}</button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import FormControlErrors from "../FormControlErrors";
import api from '../../api'
import {ElNotification} from "element-plus";

export default {
  components: {FormControlErrors},
  emits: ['created'],
  data: function() {
    return {
      topic: null,
      body: null,
      errors: {},
      loading: false,
    }
  },

  methods: {
    handleCreateForm(e) {
      e.preventDefault()
      this.loading = true
      this.errors = {}
      api.storeIssue(this.topic, this.body)
        .then(() => {
          this.errors = {}
          this.topic = null
          this.body = null
          ElNotification.success({
            title: this.$dictionary.issue_create_success_message,
          })
          this.$emit('created')
        })
        .catch(e => {
          if(e.response.status === 422) this.errors = e.response.data.errors
        })
        .finally(() => { this.loading = false })
    }
  }
}
</script>