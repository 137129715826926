<template>
  <div>
      <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>
  </div>
</template>

<script>
import {StreamBarcodeReader} from "vue-barcode-reader";
import {ref} from "vue";

export default {
  name: "camera-scanner",
  components: {StreamBarcodeReader},
  data() {
    return {
      decodedText: ref(''),
      isActive: false
    }
  },
  methods: {
    onDecode(e) {
      if (e.length > 5) {
        this.$emit('decoded', e);
      }
    },
    onLoaded() {
    },
  }
}
</script>

<style scoped>
a {
  color: #42b983;
}

.information {
  margin-top: 100px;
}
</style>