<template>

    <div v-if="updateExists">
      <div class="container">
        <div class="row">
          <div class="app-update col-12 my-4">
            <span class="message">{{ $dictionary.appUpdateMessage }}</span>
            <button class="btn btn-sm btn-primary mx-2" @click="refreshApp">{{ $dictionary.appUpdateBtnLabel }}</button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="appLoading" class="app-loading-container d-flex align-items-center justify-content-center">
      <div>
        <img :src="$theme.media.loading"/>
        <p>Ładowanie...</p>
      </div>
    </div>

    <div v-if="!appLoading" v-loading="$store.state.fullscreenLoading" class="row p-0 m-0">
      <div v-if="!navDisabled" class="col col-lg-4 col-xl-3 p-0 m-0 col-auto d-none d-lg-block nav-desktop-wrapper">
        <account-info-aside></account-info-aside>
        <desktop-nav-menu></desktop-nav-menu>
      </div>
      <div class="col p-0 m-0 min-vh-100" :class="{
        'col-lg-8 col-xl-9': !navDisabled
      }">
        <router-view v-slot="{ Component }">
          <transition name="slide-fade">
            <component :is="Component" />
          </transition>
        </router-view>

        <footer class="text-center" :class="{ 'd-none': footerDisabled }">
          <img :src="$theme.media.logoDark"/>
          <p class="copyright mt-4">{{ $dictionary.footer_copyright }}</p>
        </footer>
      </div>
    </div>

    <div v-if="!appLoading" class="d-block d-lg-none sm-nav-container position-fixed">
      <mobile-nav-bar v-if="!navDisabled"></mobile-nav-bar>
    </div>
</template>

<script>
import MobileNavBar from "./components/nav/MobileNavBar";
import DesktopNavMenu from "./components/nav/DesktopNavMenu";
import AccountInfoAside from "./components/AccountInfoAside";
import api from './api'
import update from "@/mixins/update";

export default {
  components: {AccountInfoAside, DesktopNavMenu, MobileNavBar},
  mixins: [update],
  data() {
    return {
      appLoading: true,
    }
  },
  computed: {
    navDisabled() {
      return this.$route.meta.disableNav
    },
    footerDisabled() {
      return this.$route.name === 'Card'
    }
  },
  created() {
    this.$store.dispatch('loadAppConfig')
    api.getLoggedIn()
      .then(response => {
        this.$store.commit('SET_USER', response.data);

        if(this.$route.name !== 'Event') {
          this.$router.push('/start');
        }
      })
      .catch(e => {
        if(e.response.status === 401 && !window.location.href.includes('/page/')) {
          this.$store.commit('SET_USER', null)
          if (this.$route.name !== 'RegisterConfirm') {
            this.$router.push('/')
          }
        }
      })
      .finally(() => {
        this.appLoading = false
      })
  }
}
</script>

<style lang="scss" scoped>

.app-update {
  display: flex;
  align-items: center;

  .message {
    display: block;
    font-size: 2rem;
    font-weight: bold;
  }

}

.sm-nav-container {
  z-index: 100;
  bottom: 0;
  width: 100%;
  margin-top: 0;
}

.nav-desktop-wrapper{
  z-index: 1;
  @extend %nav-desktop-wrapper !optional;
}

.copyright { @extend %footer-text-copyright; }

footer {
  padding-top: 3rem;
  padding-bottom: 3rem;
  @extend %footer-container-copyright;
}

.app-loading-container {
  width: 100%;
  height: 100vh;

  p {
    text-align: center;
  }

  @extend %app-loading-container;
}
</style>

<style lang="scss">
@import "./node_modules/bootstrap/scss/bootstrap";
@import "./node_modules/bootstrap/scss/variables";
@import "assets/icomoon/style.css";
@import "cuttlefish_theme";

body {
  /* Disables pull-to-refresh but allows overscroll glow effects. */
  overscroll-behavior-y: auto;
}

.slide-fade-enter-active {
  transition: all 0.5s cubic-bezier(.45,.75,.65,1);
  transition-delay: 0.5s;
}
.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(.45,.75,.65,1);
}

.slide-fade-enter-from {
  transform: translateY(-20px);
  opacity: 0;
}
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
.modal .modal-close {
  background-repeat: no-repeat !important;
}
.vueperslides__bullets {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 767.98px) {
  .modal .modal-animation {
    top:auto !important;
    bottom: auto !important;
  }
}
</style>
