<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="new-issue">
            <button class="collapse-issue-form-btn" type="button" data-bs-toggle="collapse" data-bs-target="#new-issue">
              <span></span>
              {{ $dictionary.issue_create_form_heading }}
            </button>
            <div class="collapse" id="new-issue">
              <create-issue-form @created="loadIssues"/>
            </div>
          </div>
        </div>
      </div>
      <div class="row issue-list-filter">
        <div class="btn-group col" role="group">
            <button @click="type = 'open'" type="button" :class="{'btn-primary': type === 'open', 'btn-outline-primary': type !== 'open'}" class="btn w-100"><i class="icon-lock"/>{{ $dictionary.issues_view_open_label }}</button>
            <button @click="type = 'closed'" type="button" :class="{'btn-primary': type !== 'open', 'btn-outline-primary': type === 'open'}" class="btn w-100"><i class="icon-lock"/>{{ $dictionary.issues_view_archive_label }}</button>
        </div>
      </div>
      <div v-loading="loading" class="row">
        <div class="col">
          <issues-listing-item v-for="(i, index) in issues" :isUnread="i.is_unread"
             :key="index"
             :title="i.title"
             :body="i.messages[0].body"
             @click="$router.push('/account/issues/'+ i.uuid)"/>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import CreateIssueForm from "@/components/forms/CreateIssueForm";
import IssuesListingItem from "@/components/listing-items/IssuesListingItem";
import api from "@/api";

export default {
  name: "IssuesIndex",
  components: {IssuesListingItem, CreateIssueForm},
  data(){
    return {
      loading: false,
      type: 'open',
    }
  },
  computed: {
    issues() {
      return this.$store.state.issues.filter(i => (this.type === 'open' ? i.is_open : !i.is_open))
    }
  },
  created() {
    if(this.issues.length === 0) this.loading = true
    this.loadIssues()
  },
  methods: {
    loadIssues() {
      api.getIssues()
        .then( response => {
          this.$store.commit('SET_ISSUES', response.data)
        })
        .catch()
        .finally(() => { this.loading = false })
    }
  }
}
</script>

<style lang="scss">
.new-issue {
  @extend %new-issue !optional;
}
.issue-list-filter {
  @extend %issue-list-filter !optional;
}
</style>