<template>
  <div class="d-flex flex-row gap-3 notification-item">

    <div class="d-flex flex-column w-100">

      <div class="d-flex flex-row gap-3 justify-content-between">
        <div>
          <h5>{{ notification.title }}</h5>
          <p v-if="notification.intro" class="message mt-2">{{ notification.intro }}</p>
        </div>

        <template v-if="!notification.read_at" >
          <div class="notification-indicator"></div>
        </template>
      </div>

      <div class="text-end">
        <span>{{ notification.sent }}</span>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: "NotificationsListingItem",
  props: {
    notification: Object,
  }
}
</script>

<style scoped lang="scss">

.notification-item {
  flex-shrink: 0;
  display: flex;
  justify-content: flex-start;

  &:hover {
    cursor: pointer;
  }

  @extend %notification-item;
}

.notification-indicator {
  flex-shrink: 0;
  background: #E80B06;
  width: 15px;
  height: 15px;
  border-radius: 100%;
}

.message {
  white-space: pre-line;
}

</style>
