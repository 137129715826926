<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col d-lg-none">
          <view-title :title="viewTitle"
                      v-on="{ back: isNestedRouteSelected ? () => { $router.push('/account') } : null }">
          </view-title>
        </div>
        <div class="d-none d-lg-block">
          <view-title :title="viewTitle"></view-title>
        </div>
      </div>
      <div class="row">
        <div class="col col-lg-5 d-lg-block nav-account-container" :class="{ 'd-none': isNestedRouteSelected }">
          <account-nav></account-nav>
        </div>
        <div class="col" :class="{ 'd-block': isNestedRouteSelected, 'd-none': !isNestedRouteSelected}">
          <router-view v-slot="{ Component }">
            <transition name="slide-fade">
              <component :is="Component" />
            </transition>
          </router-view>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ViewTitle from "../../../components/ViewTitle";
import AccountNav from "../../../components/nav/AccountNav";
import api from "../../../api";

export default {
  name: "Account",
  components: {AccountNav, ViewTitle},
  computed: {
    isNestedRouteSelected() {
      return this.$route.name !== 'Account'
    },
    viewTitle() {
      const accountTitles = {
        'Account': this.$dictionary.account_view_title_account,
        'AccountPage': this.$dictionary.account_view_title_account_page
      };

      const standardTitles = {
        'PersonalData': this.$dictionary.account_view_title_personal_data,
        'Password': this.$dictionary.account_view_title_password_change,
        'LoyaltyAccountChanges': this.$dictionary.account_view_title_loyalty_account_changes,
        'ReceiptsIndex': this.$dictionary.account_view_title_receipts,
        'Agreements': this.$dictionary.account_view_title_agreements,
        'IssuesIndex': this.$dictionary.account_view_title_issues,
        'Receipt': this.$dictionary.account_view_title_receipts
      };

      if (typeof standardTitles[this.$route.name] !== 'undefined') {
        return standardTitles[this.$route.name];
      } else if (typeof accountTitles[this.$route.name] !== 'undefined') {
        let personalIdentity = this.$store.state?.loggedUser?.personal_identity;

        if ((personalIdentity?.first_name && personalIdentity.first_name.length > 0)
          || (personalIdentity?.last_name && personalIdentity.last_name.length > 0)
        ) {
          return personalIdentity?.first_name + ' ' + personalIdentity?.last_name;
        }

        return accountTitles[this.$route.name];
      }

      return this.$route.name;
    }
  },
  created() {
    api.getLoggedIn()
      .then(response => {
        this.$store.commit('SET_USER', response.data)
      })
      .catch(e => {
        if(e.response.status === '401') {
          this.$router.push('/')
        }
      })
  }
}
</script>

<style scoped lang="scss">

</style>