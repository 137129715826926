
import logoDark from 'cuttlefish_theme_dir/media/img/logo.png'
import logoLight from 'cuttlefish_theme_dir/media/img/logo_light.png'
import card from 'cuttlefish_theme_dir/media/img/card.png'
import placeholderOffer from 'cuttlefish_theme_dir/media/img/offer-placeholder.png'
import placeholderEvent from 'cuttlefish_theme_dir/media/img/event-placeholder.png'
import placeholderReward from 'cuttlefish_theme_dir/media/img/reward-placeholder.png'
import homeHeader from 'cuttlefish_theme_dir/media/img/home-header.png'
import loading from 'cuttlefish_theme_dir/media/img/loading.png'
import homeAppDownload from 'cuttlefish_theme_dir/media/img/home-app-download.png'
import homeAppDownloadMobile from 'cuttlefish_theme_dir/media/img/home-app-download-mobile.png'
import homeAppDownloadTitle from 'cuttlefish_theme_dir/media/img/home-app-download-title.svg'
import appStoreBadge from 'cuttlefish_theme_dir/media/img/apple-store-badge.svg'
import googlePlayBadge from 'cuttlefish_theme_dir/media/img/google-play-badge.svg'

const THEME = process.env.VUE_APP_THEME;

/*
 * Conditionally imports
 */
let ecoSlider = null;
let ecoAdv = null;

if(THEME === 'cuttlefish-theme-posnania') {
    try {
        ecoSlider = require('cuttlefish_theme_dir/media/img/slider_eco.png');
        ecoAdv = require('cuttlefish_theme_dir/media/img/eco_adv.png');
    } catch (e) {
        console.log(e);
    }
}

/*
 * Export module
 */
export default {
    media: {
        logoDark: logoDark,
        logoLight: logoLight,
        card: card,
        placeholderEvent: placeholderEvent,
        placeholderOffer: placeholderOffer,
        placeholderReward: placeholderReward,
        homeHeader: homeHeader,
        loading: loading,
        homeAppDownload: homeAppDownload,
        homeAppDownloadMobile: homeAppDownloadMobile,
        homeAppDownloadTitle: homeAppDownloadTitle,
        appStoreBadge: appStoreBadge,
        googlePlayBadge: googlePlayBadge,
        ecoSlider: ecoSlider,
        ecoAdv: ecoAdv
    }
}
