<template>
  <div class="row">
    <div v-if="!disableAcceptAll" class="col-12">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" id="check_all" @click="handleAllAccepted()" v-model="allAccepted">
        <label class="form-check-label" for="check_all">{{ getTranslationsMix($dictionary.agreements_accept_all_label) }}</label>
      </div>
      <hr/>
    </div>
    <div v-for="(agr, index) in availableAgreements" :key="'agr_'+index" class="col-12 mb-3">
      <div class="form-check">
        <input v-model="agreements[agr.uuid]" @change="handleChange"
               :class="{'is-invalid': errors['agreements.'+agr.uuid]}"
               class="form-check-input" type="checkbox" :id="'check_'+agr.uuid" :required="agr.is_required">
        <div class="label_container">
          <label class="form-check-label" :for="'check_'+agr.uuid">
            <div v-html="_n(agr.short_content) + (agr.is_required ? '<span class=\'text-danger\'> *</span>' : '')"></div>
          </label>
          <form-control-errors :error-bag="errors" :field="'agreements.'+agr.uuid"></form-control-errors>
        </div>
        <div v-if="agr.short_content !== agr.full_content" class="icon_more_container"
             @click="activeAgreementUuid = agr.uuid">
          <i class="icon-read"></i>
        </div>
      </div>
    </div>
    <modal :visibility="!!activeAgreement"
           v-if="!!activeAgreement"
           @close="hideModal"
           :hideCallback="hideModal"
           close
    >
      <div class="row modal-container-body">
        <div class="col-12">
          <div v-if="activeAgreement.full_content" v-html="__(activeAgreement.full_content)"></div>
        </div>
        <div class="col-12 text-center mt-5">
          <button class="btn btn-primary" @click="acceptAgreement(activeAgreement.uuid)">{{ getTranslationsMix($dictionary.agreements_accept_modal_label) }}</button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import FormControlErrors from "./FormControlErrors";
import Modal from "@/components/utilities/Modal";
import { preprocessor } from '@/mixins/utilities'

export default {
  name: "AgreementSet",
  components: {
    Modal,
    FormControlErrors
  },
  mixins: [preprocessor],
  props: {
    availableAgreements: {
      type: Array,
      required: true,
    },
    errors: {
      type: Object
    },
    disableAcceptAll: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      allAccepted: false,
      agreements: {},
      activeAgreementUuid: null,
      checkedAll: false,
    }
  },
  computed: {
    acceptedAgreements() {
      return this.agreements
    },
    activeAgreement(){
      return this.availableAgreements.find(x => x.uuid === this.activeAgreementUuid)
    }
  },
  created() {
    this.availableAgreements.forEach(a => {
      this.agreements[a.uuid] = false
    })
    this.$emit('update:agreements', this.acceptedAgreements)
  },
  methods: {
    checkAll(accept = true) {
      this.availableAgreements.map(agr => {
        this.manageAgreement(agr.uuid, accept)
      })
      this.$emit('update:agreements', this.acceptedAgreements)
    },
    handleAllAccepted() {
      this.checkedAll = !this.checkedAll
      if(this.checkedAll) {
        this.checkAll(true)
      } else {
        this.checkAll(false);
      }
      this.$nextTick(this.updateAllAccepted)
    },
    manageAgreement(uuid, accept = true) {
      this.agreements[uuid] = accept

      if (accept) {
        this.activeAgreementUuid = null
      }
    },
    handleChange() {
      this.$emit('update:agreements', this.acceptedAgreements)
      this.updateAllAccepted()
    },
    updateAllAccepted() {
      let hasFalse = false;
      this.availableAgreements.map(agr => {
        if(!this.agreements[agr.uuid]) hasFalse = true
      })
      this.allAccepted = !hasFalse;
      this.checkedAll = !hasFalse;
    },
    hideModal() {
      this.activeAgreementUuid = null
    }
  }
}
</script>

<style scoped lang="scss">
.label_container {
  display: inline-block;
}
.icon_more_container {
  float: right;
  cursor: pointer;
}
</style>
