<template>
  <div class="carousel-container carousel-inner" ref="container">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "CarouselContainer",
  props: {
    scroll: Number,
  },
  watch: {
    scroll(nv) {
      this.$refs.container.scroll(nv, 0)
    },
  },
}
</script>

<style scoped lang="scss">
.carousel-container {
  scroll-behavior: smooth;
  display: flex;
  overflow-x: hidden;
  gap: 1.5rem;

  > * {
    display: block;
    flex-shrink: 0;
  }
}
</style>

<style lang="scss">
.carousel-container > * {
  display: block;
  flex-shrink: 0;
}
</style>