<template>
  <main>
    <div>
      <div class="row">
        <div class="btn-group" role="group">
          <button type="button" @click="handleShowType('all')" class="btn"
                  :class="showType == 'all' ? 'btn-primary' : 'btn-secondary'">{{ $dictionary.receipt_all_label }}
          </button>
          <button type="button" @click="handleShowType('prepared')" class="btn"
                  :class="showType == 'prepared' ? 'btn-primary' : 'btn-secondary'">
            {{ $dictionary.receipt_prepared_label }}
          </button>
        </div>
      </div>
      <router-link v-bind:to="'/account/receipts/' + receipt.uuid" class="row mt-5 receipt-row" v-for="(receipt, index) in receipts" :key="index">
        <div class="col-3">
          <img v-if="!receipt.preview" src="../../../assets/icons/default_receipt.jpg" width="80" height="80">
          <img v-if="receipt.preview" v-bind:src="receipt.preview" width="80" height="80">
        </div>
        <div class="col-9">
          <div class="row">
            <div class="col-10">
              <span class="text-left text-muted p-2">{{ getStatusName(receipt) }}</span>
            </div>
            <div class="col-2">
              <img v-if="receipt.status == 'processing'" src="../../../assets/icons/receipt_processed.jpg" width="20" height="20">
              <img v-if="receipt.status == 'accepted'" src="../../../assets/icons/receipt_accepted.jpg" width="20" height="20">
              <img v-if="receipt.status == 'rejected'" src="../../../assets/icons/receipt_canceled.jpg" width="20" height="20">
            </div>
          </div>
          <div class="row">
            <div class="col p-4" style="font-weight: 500">
              {{ getDescription(receipt) }}
            </div>
          </div>
          <div class="row mt-2">
            <div class="col text-right">
              <small class="text-muted">{{ receipt.created_at }}</small>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </main>
</template>

<script>
import api from "@/api";

export default {
  name: "ReceiptsIndex",
  data() {
    return {
      receipts: null,
      showType: 'all'
    }
  },
  mounted() {
    const that = this
    api.userReceipts()
        .then((response) => {
          that.receipts = response.data
        });
  },
  methods: {
    handleShowType(type) {
      const that = this
      this.showType = type;
      api.userReceipts({type: that.showType})
          .then((response) => {
            that.receipts = response.data
          });
    },
    getStatusName(receipt) {
      if (receipt.status === 'processing') {
        return this.$dictionary.receipt_preparation_label
      } else if (receipt.status === 'accepted') {
        return this.$dictionary.receipt_accepted_label
      } else {
        return this.$dictionary.receipt_canceled_label
      }
    },
    getDescription(receipt) {
      if (receipt.status === 'processing') {
        return this.$dictionary.receipt_preparation_long_text
      } else if (receipt.status === 'accepted') {
        let totalValue = parseInt(receipt.total_value);

        if (totalValue > 0) {
          totalValue = Math.round(totalValue / receipt.promotional_event_points_divider);
        }

        return this.$dictionary.receipt_accepted_long_text + ' ' + totalValue
      } else {
        return this.$dictionary.receipt_canceled_long_text
      }
    }
  }
}
</script>

<style scoped>
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.receipt-row {
  border-bottom: 1px #a9a9a9 solid;
  text-decoration: inherit;
  color: inherit;
}
</style>