<template>
  <div class="receipt-scanner">

    <div v-if="receiptAgreements && receiptAgreementsCount > 0">
      <div class="row">
        <div class="col-12">
          <h5>Skanowanie paragonu - zaakceptuj zgody</h5>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <agreement-set v-model:agreements="updatedAgreements"
                         :errors="errors"
                         :available-agreements="receiptAgreements"
                         @update:agreements="onUpdatedAgreements"
          >
          </agreement-set>
        </div>

        <div class="col-12" v-if="updatedAgreementsShowBtn">
          <button class="btn btn-dark" @click="updateAgreements">Zaakceptuj zgody</button>
        </div>

      </div>
    </div>

    <div v-else-if="selectShopView">
      <div class="row">
        <div class="col-12">
          <h5>Skanowanie paragonu - wybór sklepu</h5>
        </div>
      </div>

      <div class="row">
        <div class="col mt-5 p-4">
          <el-select v-model="selectedShop" style="display: contents">
            <el-option v-for="(shop, index) in shops" :key="index" :value="shop.uuid" :label="shop.name"></el-option>
          </el-select>
        </div>
      </div>

      <div class="row" v-if="selectedShop">
        <div class="col text-center">
          <button type="button" class="btn btn-outline-secondary" @click="openScanStep" style="border-radius: 0; margin-top: 1rem">Dalej</button>
        </div>
      </div>

    </div>

    <div v-else-if="scannerView" >

      <div class="row" v-if="!cameraActive">
        <div class="col-12" v-if="!loading">

          <image-uploader @load="onImageDataLoad" @filesize="onFilesize"/>

          <div class="text-center mb-3" style="height: 91%">
            <div class="text-center" >Lub</div>
            <button type="button" @click="openCamera"
                    class="btn btn-outline-secondary"
                    style="border-radius: 0; margin-top: 1rem">Otwórz aparat</button>
          </div>

        </div>
        <div class="col-12" v-else>
          <span>Przesyłanie skanu</span>
        </div>
      </div>

      <div class="row" v-else>
        <div class="col-12" v-if="!loading">
          <div class="row">
            <div class="col-12 text-center">
              <web-cam class="web-cam" @cameras="setCamera" ref="webCam" style="width:100%"></web-cam>
            </div>
            <div class="col-12 text-center mt-1">
              <span class="text-info">{{ $dictionary.scanner_info }}</span>
            </div>
            <div class="col-12 text-center mt-2">
              <button class="btn btn-dark mx-2" @click="closeCamera">Powrót</button>
              <button class="btn btn-light mx-2" @click="scanReceipt">Skanuj</button>
            </div>
          </div>
        </div>

        <div class="col-12" v-else>
          <span>Przesyłanie skanu</span>
        </div>

      </div>

    </div>

  </div>

</template>

<script>
import AgreementSet from "@/components/AgreementSet";
import {ElNotification} from "element-plus";
import api from "@/api";
import WebCam from "@/components/WebCam";
import ImageUploader from "@/views/authenticated/receipts/ImageUploader";

export default {
  name: "ReceiptScanner",
  components: {
    AgreementSet,
    ImageUploader,
    WebCam
  },
  data() {
    return {
      loading: false,
      receiptAgreements: this.$store.state.loggedUser.receipt_scanner_agreements,
      updatedAgreements: null,
      updatedAgreementsShowBtn: false,
      errors: {},
      selectShopView: false,
      shops: null,
      scannerView: false,
      selectedShop: null,
      cameraActive: false
    }
  },
  computed: {
    receiptAgreementsCount() {
      return this.receiptAgreements.length;
    },
    updatedAgreementsCount() {
      if(this.updatedAgreements === null) {
        return 0;
      }
      return this.receiptAgreements.length;
    }
  },
  mounted() {
    console.log('Zgody', this.receiptAgreementsCount);

    if(this.receiptAgreementsCount === 0) {
      this.selectShopView = true;
    }

    this.getShops();

  },
  methods: {
    updateAgreements() {
      this.loading = true
      const that = this;

      api.updateScannerAgreements({ agreements: this.updatedAgreements })
          .then(() => {
            ElNotification.success({
              title: this.$dictionary.form_agreements_update_success_message,
            })
          })
          .catch((e) => {
            if(e.response.status === 422) this.errors = e.response.data.errors;
          })
          .finally(() => {
            that.loading = false;

            if (that.updatedAgreementsCount === that.receiptAgreementsCount) {
              that.receiptAgreements = []
            }

            that.selectShopView = true;
          })

    },
    onUpdatedAgreements() {
      const that = this;

      const values = Object.values(this.updatedAgreements);

      const selected = values.filter(value => {
        if (value === true) {
          return value;
        }
      })

      this.updatedAgreementsShowBtn = selected.length === that.receiptAgreementsCount;

    },
    getShops() {
      const that = this;

      api.getShops().then(response => {
        that.shops = response.data;
      })
    },
    openScanStep() {
      this.selectShopView = false;
      this.scannerView = true;
    },
    storeReceipt(receiptImageData) {
      this.loading = true;
      const that = this;

      api.storeReceipt(this.selectedShop, receiptImageData)
          .then(() => {
            console.log('SUCCESS!!');

            ElNotification.success({
              title: 'Gratulacje! Twój paragon został przesłany oraz zaakceptowany.',
              duration: 10000
            })

            that.resetComponent();
          })
        .catch(error => {
          console.log('ERROR!!');
          let messages = '';
          for (let err of Object.keys(error.response.data.errors)) {
            console.log(error.response.data.errors[err][0])
            messages += error.response.data.errors[err][0]
          }

          ElNotification.error({
            title: messages,
            duration: 10000
          })
          that.resetComponent();
        })
      ;
    },
    openCamera() {
      this.cameraActive = true;
    },
    closeCamera() {
      this.cameraActive = false;
    },
    setCamera(e) {
      this.cameras = e;
      let deviceId = this.cameras[this.cameras.length - 1].deviceId;
      this.$refs.webCam.changeCamera(deviceId);
    },
    scanReceipt() {
      const receiptImageData = this.$refs.webCam.capture();

      this.storeReceipt(receiptImageData);
    },
    onImageDataLoad(receiptImageData) {
      this.storeReceipt(receiptImageData);
    },
    onFilesize(filesizeMiB) {
      ElNotification.error({
        title: `Rozmiar pliku ${filesizeMiB} MiB, jest za duży.`,
      })
    },
    resetComponent() {
      this.loading = false;
      this.scannerView = false;
      this.cameraActive = false;

      this.selectShopView = true;
      this.selectedShop = null;
    }
  }
}
</script>

<style scoped>

.web-cam {
  width: 100%;
  height: 500px;
}
</style>
