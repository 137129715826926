<template>
  <div v-loading="loading">
    <div v-if="cfg.fields.email.enabled" class="row mb-5">
      <div class="col">
        <el-input v-model="personalData.email"
                  type="email"
                  prefix-icon="icon-mail"
                  :placeholder="$dictionary.form_personal_data_email_placeholder + (cfg.fields.email.required ? ' *' : '')">
        </el-input>
        <form-control-errors :error-bag="errors" field="email"></form-control-errors>
        <div v-if="!emailVerified && personalData.email">
          <button v-if="this.verification.email === null" class="btn btn-dark mt-3" @click="requestEmailVerification">
            {{ $dictionary.form_personal_data_email_verify_request_button_label }}
          </button>
          <div v-if="this.verification.email !== null">
            <el-input class="mt-3" v-model="verification.email" :placeholder="$dictionary.form_personal_data_email_verify_code_placeholder"></el-input>
            <form-control-errors :error-bag="errors" field="authorization_code"></form-control-errors>
            <button class="btn btn-dark mt-3" @click="verifyEmail">{{ $dictionary.form_personal_data_email_verify_button_label }}</button>
          </div>
        </div>
        <div v-else-if="personalData.email" class="badge bg-success mt-2">{{ $dictionary.form_personal_data_email_verify_verified_info }}</div>
      </div>
    </div>

    <div v-if="cfg.fields.phone_number.enabled" class="row mb-5">
      <div class="col">
        <el-input v-model="personalData.phone_number"
                  prefix-icon="icon-phone"
                  :placeholder="$dictionaryform_personal_data_phone_number_placeholder + (cfg.fields.phone_number.required ? ' *' : '')">
        </el-input>
        <form-control-errors :error-bag="errors" field="phone_number"></form-control-errors>
        <div v-if="!phoneNumberVerified && personalData.phone_number">
          <button v-if="this.verification.phone_number === null" class="btn btn-dark mt-3" @click="requestPhoneNumberVerification">
            {{ $dictionary.form_personal_data_phone_number_verify_request_button_label }}
          </button>
          <div v-if="this.verification.phone_number !== null">
            <el-input class="mt-3" v-model="verification.phone_number" :placeholder="$dictionary.form_personal_data_phone_number_verify_code_placeholder"></el-input>
            <form-control-errors :error-bag="errors" field="authorization_code"></form-control-errors>
            <button class="btn btn-dark mt-3" @click="verifyPhoneNumber">{{ $dictionary.form_personal_data_phone_number_verify_button_label }}</button>
          </div>
        </div>
        <div v-else-if="personalData.phone_number" class="badge bg-success mt-2">{{ $dictionary.form_personal_data_phone_number_verify_verified_info }}</div>
      </div>
    </div>

    <div class="row">
      <div v-if="cfg.fields.first_name.enabled" class="col-12 mb-3">
        <div class="form-control-container">
          <el-input v-model="personalData.first_name"
                    prefix-icon="icon-user"
                    :placeholder="$dictionary.form_personal_data_first_name_placeholder + (cfg.fields.first_name.required ? ' *' : '')">
          </el-input>
          <form-control-errors :error-bag="errors" field="first_name"></form-control-errors>
        </div>
      </div>

      <div class="col-12 mb-3">
        <div v-if="cfg.fields.last_name.enabled" class="form-control-container">
          <el-input v-model="personalData.last_name"
                    prefix-icon="icon-user"
                    :placeholder="$dictionary.form_personal_data_last_name_placeholder + (cfg.fields.last_name.required ? ' *' : '')">
          </el-input>
          <form-control-errors :error-bag="errors" field="last_name"></form-control-errors>
        </div>
      </div>

      <div v-if="cfg.fields.username.enabled" class="col-12 mb-3">
        <div class="form-control-container">
          <el-input v-model="personalData.username"
                    prefix-icon="icon-user"
                    :placeholder="$dictionary.form_personal_data_username_placeholder + (cfg.fields.username.required ? ' *' : '')">
          </el-input>
          <form-control-errors :error-bag="errors" field="username"></form-control-errors>
        </div>
      </div>

      <div v-if="cfg.fields.gender.enabled" class="col-12 mb-3">
        <div class="form-control-container">
          <el-select v-model="personalData.gender"
                     style="width: 100%"
                     :placeholder="$dictionary.form_personal_data_gender_placeholder + (cfg.fields.gender.required ? ' *' : '')">
            <el-option :label="$dictionary.form_personal_data_gender_option_male" value="male"></el-option>
            <el-option :label="$dictionary.form_personal_data_gender_option_female" value="female"></el-option>
            <el-option v-if="!cfg.fields.gender.required" :label="$dictionary.form_personal_data_gender_option_null" value=""></el-option>
            <template v-slot:prefix><i class="el-input__icon icon-user"></i></template>
          </el-select>
          <form-control-errors :error-bag="errors" field="gender"></form-control-errors>
        </div>
      </div>

      <div v-if="cfg.fields.postal_code.enabled" class="col-4">
        <div class="form-control-container">
          <el-input v-model="personalData.postal_code"
                    v-maska="'##-###'"
                    prefix-icon="icon-pin"
                    :placeholder="$dictionary.form_personal_data_postal_code_placeholder + (cfg.fields.postal_code.required ? ' *' : '')">
          </el-input>
          <form-control-errors :error-bag="errors" field="postal_code"></form-control-errors>
        </div>
      </div>

      <div v-if="cfg.fields.birthday.enabled" class="col-8">
        <div class="form-control-container">
          <el-date-picker v-model="personalData.birthday"
                          style="width: 100%"
                          prefix-icon="icon-calendar"
                          :placeholder="$dictionary.form_personal_data_birthday_placeholder + (cfg.fields.birthday.required ? ' *' : '')">
          </el-date-picker>
          <form-control-errors :error-bag="errors" field="birthday"></form-control-errors>
        </div>
      </div>
    </div>

    <div class="row mt-5 mb-5">
      <div class="col">
        <button class="btn btn-dark" @click="handleSubmit">{{ $dictionary.form_personal_data_submit_button_label }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import FormControlErrors from "../FormControlErrors";
import api from '../../api'
import { ElNotification } from "element-plus";

export default {
  name: "PersonalDataForm",
  components: {FormControlErrors},
  data() {
    return {
      personalData: {
        email: null,
        phone_number: null,
        first_name: null,
        last_name: null,
        birthday: null,
        gender: null,
        postal_code: null,
      },
      errors: {},
      loading: false,
      verification: {
        email: null,
        phone_number: null,
      }
    }
  },
  computed: {
    cfg() {
      if(!this.$store.state.config.customerRegisterConfig) return {}
      return this.$store.state.config.customerRegisterConfig
    },
    emailVerified() {
      return this.$store.state.loggedUser.personal_identity.email_verified_at
    },
    phoneNumberVerified() {
      return this.$store.state.loggedUser.personal_identity.phone_number_verified_at
    }
  },
  created() {
    this.personalData.email = this.$store.state.loggedUser.personal_identity.email
    this.personalData.phone_number = this.$store.state.loggedUser.personal_identity.phone_number
    this.personalData.first_name = this.$store.state.loggedUser.personal_identity.first_name
    this.personalData.last_name = this.$store.state.loggedUser.personal_identity.last_name
    this.personalData.birthday = this.$store.state.loggedUser.personal_identity.birthday
    this.personalData.gender = this.$store.state.loggedUser.personal_identity.gender
    this.personalData.postal_code = this.$store.state.loggedUser.personal_identity.postal_code
  },
  methods: {
    handleSubmit() {
      this.loading = true
      this.errors = {}
      api.updatePersonalData(this.personalData)
        .then(response => {
          ElNotification.success({
            title: this.$dictionary.form_personal_data_success_message,
          })
          this.$store.commit('SET_USER', response.data)
        })
        .catch(e => {
          if(e.response.status === 422) this.errors = e.response.data.errors
        })
        .finally(() => { this.loading = false })
    },
    requestEmailVerification() {
      this.loading = true
      api.requestEmailVerification()
        .then(() => {
          this.verification.email = ''
          ElNotification.success({
            title: this.$dictionary.form_personal_data_verification_email_sent_success_message,
          })
        })
        .finally(() => { this.loading = false })
    },
    requestPhoneNumberVerification() {
      this.loading = true
      api.requestPhoneNumberVerification()
          .then(() => {
            this.verification.phone_number = ''
            ElNotification.success({
              title: this.$dictionary.form_personal_data_verification_phone_number_sent_success_message,
            })
          })
          .finally(() => { this.loading = false })
    },
    verifyEmail() {
      this.loading = true
      api.verifyEmail(this.verification.email)
        .then(() => {
          this.personalData.email_verified_at = new Date()
        })
        .catch(e => {
          if(e.response.status === 422) this.errors = e.response.data.errors
        })
        .finally(() => { this.loading = false })
    },
    verifyPhoneNumber() {
      this.loading = true
      api.verifyPhoneNumber(this.verification.phone_number)
          .then(() => {
            this.personalData.phone_number_verified_at = new Date()
          })
          .catch(e => {
            if(e.response.status === 422) this.errors = e.response.data.errors
          })
          .finally(() => { this.loading = false })
    }
  }
}
</script>

<style scoped>

</style>