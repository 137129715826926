<template>
  <main>
    <div class="row">
      <div class="col text-center">
        <h2>{{ $dictionary.nav_account_label_loyalty_points_history}}</h2>
      </div>
    </div>
    <div class="row mt-5" v-for="(data, index) in historyData" :key="index" style="border-bottom: 1px #a9a9a9 solid">
      <div class="col-12">
        <div class="row">
          <div class="col-10">
            <span v-if="data.action == 1" class="text-left text-muted p-2">{{ $dictionary.user_point_history_label }}</span>
            <span v-else class="text-left text-muted p-2">{{ $dictionary.user_point_history_label_substrate }}</span>
          </div>
          <div class="col-2">
            <img v-if="data.action == 0" src="../../../assets/icons/receipt_canceled.jpg" width="20" height="20">
            <img v-else src="../../../assets/icons/receipt_accepted.jpg" width="20" height="20">
          </div>
        </div>
        <div class="row">
          <div class="col p-4" style="font-weight: 500">
            <p>{{ $dictionary.user_point_history_description }} {{ data.points }}</p>
            <p v-if="data.point_sum">{{ $dictionary.user_point_history_description_sum }} {{ data.point_sum}}</p>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col text-right">
            <small class="text-muted">{{ data.created_at }}</small>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import api from "@/api";

export default {
  name: "LoyaltyAccountChanges",
  data() {
    return {
      historyData: null
    }
  },
  mounted() {
    const that = this
    api.userHistoryPoints()
        .then((response) => {
          that.historyData = response.data
        });
  },
  methods: {

  }
}
</script>

<style scoped>
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
</style>