<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col">
          <view-title :title="$dictionary.offers_view_title"></view-title>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="row row-cols-1 row-cols-lg-2">
            <offer-listing :offers="filteredOffers" />
          </div>
        </div>
        <div v-if="offerCategories.length > 0" class="col col-4 d-none d-lg-block">
          <div class="offers-filters-container">
            <h3>{{  $dictionary.offers_view_filters_heading }}</h3>
            <strong>{{ $dictionary.offers_view_filters_shops }}</strong>

            <div v-for="(oc, index) in offerCategories" :key="'oc_' + index" class="form-check">
              <input v-model="filters.category" class="form-check-input" :value="oc" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
              <label class="form-check-label" for="flexRadioDefault1">
                {{ oc }}
              </label>
            </div>

            <button class="btn btn-dark" @click="filters.category = null">{{  $dictionary.offers_view_filters_reset }}</button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ViewTitle from "../../components/ViewTitle";
import OfferListing from "@/components/OfferListing";
import api from "@/api";

export default {
  name: "Offers",
  components: {OfferListing, ViewTitle},
  data(){
    return {
      offers: [],
      filters: {
        category: null
      },
    }
  },
  computed: {
    filteredOffers(){
      let offers = [...this.offers]
      if(this.filters.category) offers = offers.filter(o => o.category === this.filters.category)
      return offers
    },
    offerCategories() {
      let categories = []
      this.offers.map(o => categories.push(o.category))
      return [...new Set(categories)]
    }
  },
  created() {
    api.getOffers()
      .then( response => {
        this.offers = response.data
      })
      .catch()
  }
}
</script>

<style scoped lang="scss">
.offers-filters-container {
  @extend %offers-filters-container;
}
</style>