<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col">
          <view-title :title="$dictionary.notifications_view_title"></view-title>
        </div>
      </div>

      <div v-if="totalLoaded > 0 && total > 0" class="row">
        <div class="col">
          <notifications-listing-item
              v-for="(notification, index) in notifications" :key="index"
              :notification="notification"
              @click="$router.push({ name: 'NotificationPage', params: { uuid: notification.uuid }})"
          ></notifications-listing-item>
        </div>
      </div>
      <div v-else class="row">
        <div class="col">
          <p>Nie masz żadnych powiadomień.</p>
        </div>
      </div>

      <div v-if="nextPageUrl" class="row">
        <div class="col text-center">
          <button @click="loadNextPage" class="btn btn-dark">{{ $dictionary.notifications_view_load_more_label }}</button>
        </div>
      </div>
    </div>

  </main>
</template>

<script>

import NotificationsListingItem from "@/components/listing-items/NotificationsListingItem";
import ViewTitle from "@/components/ViewTitle";
import api from "@/api";

export default {
  name: "NotificationsPage",
  components: {
    NotificationsListingItem,
    ViewTitle
  },
  data() {
    return {
      notifications: [],
      totalLoaded: 0,
      total: 0,
      nextPageUrl: null,
    }
  },
  created() {
    this.getNotifications();
  },
  methods: {
    getNotifications() {
      this.notifications = [];
      this.totalLoaded = 0;
      this.total = 0;
      this.nextPageUrl = null;

      api.getNotifications()
        .then(response => {

          if(response.status === 200) {
            this.notifications = response.data.data;

            this.totalLoaded = this.notifications.length;
            this.total = response.data.total;

            this.nextPageUrl = response.data.next_page_url;
          }
      })
    },
    loadNextPage() {
      api.getNotifications(this.nextPageUrl)
          .then(response => {

            if(response.status === 200) {
              this.notifications = [...this.notifications, ...response.data.data];

              this.totalLoaded = this.notifications.length;
              this.total = response.data.total;

              this.nextPageUrl = response.data.next_page_url;
            }
          })
    }
  }
}
</script>

<style scoped>

</style>
