<template>
  <div class="view-title-container">
    <div class="view-title-container-inside">
      <div v-if="$attrs.onBack" @click="$emit('back')" class="back-icon-container"><i class="icon-angle-left"></i></div>
      <div
          v-else-if="!disableBack"
          @click="$router.push(backLocation ? backLocation : '/start')"
          class="d-lg-none back-icon-container">
        <i class="icon-angle-left"></i>
      </div>

      <h1 v-bind:style="{ fontSize: fontSize ? fontSize : 'inherit' }">{{ title }}</h1>
    </div>
    <div v-if="$slots.default">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ViewTitle",
  props: {
    title: String,
    disableBack: Boolean,
    backLocation: {
      type: String,
      default: null
    },
    fontSize: String
  },
}
</script>

<style scoped lang="scss">
.back-icon-container {
  padding: 0 4px;
}
.view-title-container {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;

  @include media-breakpoint-up(lg) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 18px;

  h1 {
    margin-bottom: 0;
  }

  @extend %view-title-container;
}
.view-title-container-inside {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 18px;
}
</style>