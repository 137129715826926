<template>
  <main>
    <div :class="{'container': !clearMode, 'dashboard-rewards-catalogue': dashboardMode }">
      <div class="row" v-if="!clearMode">
        <div class="col">
          <view-title title="Katalog nagród"></view-title>
        </div>
      </div>
      <div class="row mb-5 mt-5 mt-lg-0" v-if="!clearMode">
        <div class="col">
          <div class="btn-group" role="group">
            <button @click="filter = 'all'" type="button" class="btn"
                    :class="{'btn-primary':filter === 'all', 'btn-outline-primary':filter !== 'all'}">Wszystkie</button>
            <button @click="filter = 'available'" type="button" class="btn"
                    :class="{'btn-primary':filter === 'available', 'btn-outline-primary':filter !== 'available'}">Dostępne dla mnie</button>
          </div>
        </div>
      </div>
      <div class="row mb-5 mt-5 mt-lg-0" v-if="eventMessage">
        <div class="col" v-text="eventMessage"></div>
      </div>
      <div class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3">
        <div class="col mb-4 reward-listing-col" v-for="(item, index) in rewardsList" :key="index">
          <reward-listing-item
              :reward="item"
              :with-details="true"
              :collectedPoints="collectedPoints"
              @click="$router.push('/rewards/' + item.uuid)"
          ></reward-listing-item>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ViewTitle from "../../../components/ViewTitle";
import RewardListingItem from "../../../components/listing-items/RewardListingItem";
import api from "@/api";
export default {
  name: "RewardsCatalogue",
  components: {RewardListingItem, ViewTitle},
  props: {
    dashboardMode: {
      type: Boolean,
      default: false
    },
    clearMode: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.getRewardsList();

    if (this.$store.state.config.promotionalEvents && !this.eventMessage) {
      for (let promotionalEvent of this.$store.state.config.promotionalEvents) {
        if (promotionalEvent.message) {
          this.eventMessage = promotionalEvent.message;
        }
      }
    }
  },
  data() {
    return {
      processingRewardReservation: false,
      filter: 'all',
      listData: {
        rewards: [],
        reservations: [],
      },
      rewardsList: [],
      error: null,
      modalOpen: false,
      activeRewardId: false,
      eventMessage: null
    }
  },
  watch: {
    filter() {
      this.generateRewardsList();
    }
  },
  methods: {
    generateRewardsList() {
      this.rewardsList = [];

      if (this.listData.rewards) {
        for (let rewardIndex of Object.keys(this.listData.rewards)) {
          let reservationFound = false;
          let reward = this.listData.rewards[rewardIndex];
          if (this.listData.reservations) {
            if (this.listData.reservations) {
              for (let rewardReservation of this.listData.reservations) {
                if (reward.id === rewardReservation.reward.id) {
                  reward.reservation = rewardReservation;
                  reward.reservation.reward_for_points = null;
                  reservationFound = true;
                  break;
                }
              }
            }
          }

          if (!reservationFound) {
            reward.labels = null;
            reward.reservation = null;
          }

          if (this.rewardVisible(reward)) {
            const index = this.rewardsList.findIndex(x => x.id === reward.id);

            if(index === -1) {
              this.rewardsList.push(reward);
            }

            if (this.dashboardMode && this.rewardsList.length >= 3) {
              break;
            }
          }
        }
      }
      if (this.$store.state.config.featuredRewards.length > 0 && this.filter === 'all') {
        this.$store.state.config.featuredRewards.forEach(item => {
          const index = this.rewardsList.findIndex(x => x.id === item.id);

          if(index === -1) {
            this.rewardsList.push(item);
          }
        })
      }
    },
    getRewardsList() {
      api.getPromotionalRewardsReservations().then(
        (response) => {
          this.listData.reservations = response.data;

          api.getPromotionalRewards().then(
            (response) => {
              this.listData.rewards = response.data;
              setTimeout(() => this.generateRewardsList(), 100);
            }
          );
        }
      );
    },
    rewardVisible(reward) {
      if (!reward) {
        return false;
      }

      let enoughStock = (reward?.warehouse_stock?.quantity > 0);
      let enoughPoints = ((reward?.points_needed ?? 0) <= this.collectedPoints);

      return (
          (this.filter === 'all' && (enoughStock || (reward?.reservation ?? null)))
          ||
          (this.filter === 'available' &&
              (enoughStock || (reward?.reservation ?? null)) &&
              (enoughPoints || (reward?.reservation ?? null)) &&
              ['expired', 'canceled', 'collected'].indexOf(reward?.reservation?.status) === -1
          )
      )
    }
  },
  computed: {
    collectedPoints() {
      return parseInt(this.$store.state.loggedUser && this.$store.state.loggedUser.loyalty_points && this.$store.state.loggedUser.loyalty_points.available ? this.$store.state.loggedUser.loyalty_points.available : 0);
    }
  }
}
</script>

<style scoped lang="scss">
main {
  width: 100%;
}

.collected-points-title {
  font-size: 1.3em;

  span {
    font-size: 1.5em;
    font-weight: bold;
  }
}
.points-text {
  &.missing {
    color: #6e0805;
  }
  &.available {
    color: #00620f;
  }
}
@media (max-width: 991px) {
  .dashboard-rewards-catalogue {
    .reward-listing-col:nth-child(3) {
      display: none;
    }
  }
}

@media only screen and (max-width: 768px) {
  .reward-modal-image {
    padding: 60px 20px 0 20px;
    margin-bottom: 0;
  }

  .dashboard-rewards-catalogue {
    .reward-listing-col:nth-child(3),
    .reward-listing-col:nth-child(2) {
      display: none;
    }
  }
}
.reward-error {
  background: #ffe7e7;
  border: 1px solid #6e0805;
  padding: 12px;
}
</style>
