<template>
  <div class="nav-account-container-inner">
    <ul v-if="$store.state.config.features" class="list-unstyled nav-account">
      <li v-if="$store.state.config.features.accountPersonalDataChange">
        <router-link to="/account/personal-data"><i class="icon-settings"></i><span>{{  $dictionary.nav_account_label_personal_data }}</span><i class="icon-angle-right"></i></router-link>
      </li>
      <li v-if="$store.state.config.features.accountPasswordChange">
        <router-link to="/account/password"><i class="icon-lock"></i><span>{{  $dictionary.nav_account_label_password_change }}</span><i class="icon-angle-right"></i></router-link>
      </li>
    </ul>
    <ul v-if="$store.state.config.features" class="list-unstyled nav-account">
      <li v-if="$store.state.config.features.loyaltyAccountHistory">
        <router-link to="/account/loyalty-account-changes"><i class="icon-problem"></i><span>{{  $dictionary.nav_account_label_loyalty_points_history }}</span><i class="icon-angle-right"></i></router-link>
      </li>
      <li v-if="$store.state.config.features.receiptHistory">
        <router-link to="/account/receipts"><i class="icon-charts"></i><span>{{  $dictionary.nav_account_label_receipts_history }}</span><i class="icon-angle-right"></i></router-link>
      </li>
      <li v-if="$store.state.config.features.accountAgreementChange">
        <router-link to="/account/agreements"><i class="icon-receipt"></i><span>{{  $dictionary.nav_account_label_agreements }}</span><i class="icon-angle-right"></i></router-link>
      </li>
      <li v-if="$store.state.config.features.accountIssues">
        <router-link to="/account/issues">
          <i class="icon-document"></i>
          <span>{{  $dictionary.nav_account_label_issues }}</span>
          <span class="issue-indicator-unread" v-if="$store.state.unreadIssues > 0"></span>
          <i class="icon-angle-right"></i>
        </router-link>
      </li>
    </ul>
    <ul v-if="$store.state.config.pages && $store.state.config.pages.length > 0" class="list-unstyled nav-account">
      <li v-for="(page, index) in $store.state.config.pages" :key="'page_nav_'+index">
        <router-link :to="'/account/page/'+page.uuid"><i class="icon-document"></i><span>{{ page.title }}</span><i class="icon-angle-right"></i></router-link>
      </li>
    </ul>

    <ul class="d-lg-none list-unstyled nav-account">
      <li>
        <a href="#" @click="logout"><i class="icon-logout"></i><span>{{ $dictionary.nav_account_label_logout }}</span><i class="icon-angle-right"></i></a>
      </li>
    </ul>
  </div>
</template>

<script>
import { logout } from "../../mixins/utilities";

export default {
  name: "AccountNav",
  mixins: [logout]
}
</script>

<style scoped lang="scss">
.nav-account-container-inner {
  @extend %nav-account-container-inner;
}
.nav-account {
  @extend %nav-account;
}


.issue-indicator-unread {
  background: #E80B06;
  width: 15px;
  height: 15px;
  border-radius: 100%;
}
</style>