<template>
  <div>
    <div v-if="slides.length > 0" class="row">
      <div class="col slider">
        <div id="start-slider" class="carousel start_slider_container" data-bs-ride="carousel">
          <div class="slider_overlay" data-v-5cfae77a=""></div>
          <div class="carousel-indicators">
            <button v-for="(s, index) in slides" :key="'ind_'+index" type="button" data-bs-target="#start-slider" :class="{'active': index === 0}" :data-bs-slide-to="index"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item" v-for="(s, index) in slides" :key="'s'+index" :class="{'active': index === 0}">
              <img v-if="s.desktop_url" :src="s.desktop_url" class="d-none d-lg-block w-100">
              <img v-if="s.mobile_url" :src="s.mobile_url" class="d-block d-lg-none w-100">
              <div class="carousel-caption">
                <h2 v-if="s.title">{{s.title}}</h2>
                <a v-if="s.url && s.button_title" :href="s.url">{{s.button_title}}</a>
              </div>
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#start-slider" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#start-slider" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppSlider",
  props: {
    slides: Array
  }
}
</script>

<style scoped lang="scss">
.start_slider_container {
  .carousel-inner {
    border-radius: 14px;
    -webkit-border-radius: 14px;
  }

  @extend %start_slider_container;
}
.rewards_slider_container {
  @extend %rewards-slider-container;
}
.offers_slider_container {
  @extend %offers-slider-container;
}
</style>

