<template>
  <div class="event-item-container position-relative">
    <div class="overlay"></div>
    <img :src="image ? image : $theme.media.placeholderEvent"/>
    <h3>{{ name }}</h3>
    <listing-item-labels v-if="time" :labels="[time]"></listing-item-labels>
  </div>
</template>

<script>
import ListingItemLabels from "./ListingItemLabels";
export default {
  name: "EventListingItem",
  components: {ListingItemLabels},
  props: {
    image: String,
    name: String,
    time: String,
  }
}
</script>

<style scoped lang="scss">

.event-item-container {
  cursor: pointer;
  .overlay {
    width: 100%;
    padding: 50%;
    max-height: 300px;
    position: absolute;
    background: linear-gradient(180deg, rgba(17, 17, 19, 0) 0%, rgba(17, 17, 19, 0.8) 100%);
  }
  img {
    max-width: 100%;
  }

  h3 {
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 0;
  }

  span {
    position: absolute;
    z-index: 1;
    bottom: 0;
    transform: translateY(50%);
  }

  @extend %event-item-container;
}
</style>
