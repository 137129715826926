<template>
  <div>
    <div class="reward-item-container position-relative">
      <div class="overlay unlocked" :class="{'increased without-gradient': !withDetails }">
        <span v-html="overlayMessage"></span>
      </div>
      <div class="reward-item__box">
        <div class="reward-item__box__image">
          <img :src="reward ? reward.thumbnail_url : $theme.media.placeholderReward"/>
        </div>
        <listing-item-brand
            v-if="brandImage"
            :image="brandImage ? brandImage : null"
        ></listing-item-brand>
        <div v-if="withDetails" class="reward-item-info-container points-needed">
          <span class="prefix">Należy zebrać</span>
          <span>{{ reward ? reward.points_needed : 0 }} <small>pkt</small></span>
        </div>
        <div v-if="withDetails" class="reward-item-info-container lock">
          <i class="fa" :class="{ 'icon-lock': reservationDisabled, 'icon-lock-open': !reservationDisabled }"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ListingItemBrand from "./ListingItemBrand";
export default {
  name: "RewardListingItem",
  components: { ListingItemBrand},
  props: {
    reward: Object,
    collectedPoints: Number,
    brandImage: String,
    withDetails: Boolean
  },
  computed: {
    overlayMessage() {
      let message = '';

      if ((this.reward && this.reward.reservation ? this.reward.reservation : null) === null && this.collectedPoints >= (this.reward && this.reward.points_needed ? this.reward.points_needed : 0)) {
        message = '<b>Gratulacje</b> - masz wystarczającą liczbę punktów';
      } else if (this.reward && this.reward.reservation && this.reward.reservation.status === 'collected') {
        message = '<b>ODEBRANO NAGRODĘ</b>';
      } else if (this.reward && this.reward.reservation && this.reward.reservation.status === 'reserved') {
        message = '<b>NAGRODA ZAREZERWOWANA</b>';
      } else if (this.reward && this.reward.reservation && this.reward.reservation.status === 'expired') {
        message = '<b>NIE ODEBRANO W TERMINIE</b>';
      }

      return message;
    },
    reservationDisabled() {
      return !(
          (this.reward && this.reward.reservation ? this.reward.reservation : null) === null
          &&
          (this.reward && this.reward.points_needed ? this.reward.points_needed : 0) <= this.collectedPoints
      );
    },
  }
}
</script>

<style scoped lang="scss">
.reward-item-container .overlay {
  max-height: 300px;
  width: 100%;
  padding: 50%;
  z-index: 80;
  position: absolute;
  background: linear-gradient(180deg, rgba(17, 17, 19, 0) 0%, rgba(17, 17, 19, 0.8) 100%);
  color: #ffffff;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 1);

  &.without-gradient {
    background: none;
  }

  &.locked {
    background: rgba(0,0,0,0.7);

    &:hover {
      background: rgba(0,0,0,0.6);
    }

    i {
      position: absolute;
      margin-left: -32px;
      margin-top: -32px;
      font-size: 4em;
    }
  }

  &.increased {
    i {
      font-size: 8em;
      margin-left: -64px;
      margin-top: -64px;
    }
  }

  &.unlocked {
    span {
      position: absolute;
      line-height: 1.4;
      top: 24px;
      left: 15px;
      right: 15px;
      bottom: 30px;
      text-align: center;
      font-size: 1.4em;
    }
  }
}

.reward-item-container {
  cursor: pointer;
  overflow: visible;

  .reward-item {
    &__header {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 12px;
    }

    &__box {
      &__image {
        padding-bottom: 100%;
        position: relative;

        img {
          position: absolute;
          width: 100%;
          height: auto;
          left: 0;
          top: 0;
          border-radius: 14px;
          -webkit-border-radius: 14px;
          -webkit-box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 1);
          -moz-box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 1);
          box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 1);
        }
      }
    }
  }

  img {
    max-width: 100%;
  }

  @extend %reward-item-container;
}

.reward-item-info-container {
  position: absolute;
  right: 0;
  bottom: 15px;
  z-index: 85;

  span {
    font-weight: bold;
    font-size: 1.6em;
    display: block;
    color: #ffffff;

    &.prefix {
      font-weight: normal;
      font-size: 0.8em;
    }

    small {
      font-weight: normal;
      font-size: 0.5em;
    }
  }

  &.points-needed {
    right: 15px;
  }

  &.lock {
    @extend %reward-item-info-container;

    right: auto;
    line-height: 1em;
    left: 0;
    font-size: 3em;
    padding-bottom: 0;
  }
}

@media only screen and (min-width: 480px) and (max-width: 768px) {
  .reward-item-info-container {
    span {
      font-size: 3em;

      &.prefix {
        font-size: 0.9em;
      }

      small {
        font-size: 0.5em;
      }
    }
  }
}
</style>
