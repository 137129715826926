<template>
    <div class="d-flex flex-row gap-4 decorated-heading-container">
      <h2 v-html="heading"></h2>
      <div v-if="$slots.default">
        <slot></slot>
      </div>
    </div>
</template>

<script>
export default {
  name: "Heading",
  props: {
    heading: String,
  }
}
</script>

<style scoped lang="scss">
.decorated-heading-container {
  @extend %decorated-heading-container;
}

h2 {
  flex-grow: 1;
}
</style>