<template>
  <nav class="desktop-nav-container">
    <ul v-if="$store.state.config.features" class="list-unstyled desktop-nav">
      <li>
        <router-link to="/start"><i class="icon-dashboard"></i><span>{{ $dictionary.nav_desktop_label_start }}</span></router-link>
      </li>
      <li v-if="$store.state.config.features.notifications">
        <router-link to="/notifications">
          <i class="icon-bell"></i>
          <span>{{ $dictionary.nav_desktop_label_notifications }}</span>
          <span class="indicator-unread" v-if="$store.state.unreadNotifications">{{ $store.state.unreadNotifications }}</span>
        </router-link>
      </li>
      <li v-if="$store.state.config.features.events">
        <router-link :class="{'router-link-active' : $route.matched.some(({name}) => name === 'Event')}" to="/events"><i class="icon-event"></i><span>{{ $dictionary.nav_desktop_label_events }}</span></router-link>
      </li>
      <li v-if="$store.state.config.features.rewardsCatalogue">
        <router-link to="/rewards"><i class="icon-prize"></i><span>{{ $dictionary.nav_desktop_label_rewards }}</span></router-link>
      </li>
      <li v-if="$store.state.config.features.rewardsCollected">
        <router-link to="/rewards/collected"><i class="icon-gift"></i><span>{{ $dictionary.nav_desktop_label_my_rewards }}</span></router-link>
      </li>
      <li v-if="$store.state.config.features.offers">
        <router-link to="/offers"><i class="icon-sale"></i><span>{{ $dictionary.nav_desktop_label_offers }}</span></router-link>
      </li>
      <li v-if="$store.state.config.features.receiptScan">
        <router-link to="/receipts/scan"><i class="icon-receipt"></i><span>{{ $dictionary.nav_desktop_label_scan }}</span></router-link>
      </li>
      <li v-if="$store.state.config.features.card">
        <router-link to="/card"><i class="icon-card"></i><span>{{ $dictionary.nav_desktop_label_card }}</span></router-link>
      </li>
      <li>
        <router-link to="/account">
          <i class="icon-user"></i>
          <span>{{ $dictionary.nav_desktop_label_account }}</span>
          <span class="issue-indicator-unread" v-if="$store.state.unreadIssues > 0"></span>
        </router-link>
      </li>
    </ul>
    <ul class="list-unstyled desktop-nav-logout">
      <li>
        <a href="#" @click="logout"><i class="icon-logout"></i><span>{{ $dictionary.nav_desktop_label_logout }}</span></a>
      </li>
    </ul>
  </nav>
</template>

<script>
import { logout } from "../../mixins/utilities";

export default {
  name: "DesktopNavMenu",
  mixins: [logout],
}
</script>

<style scoped lang="scss">
.desktop-nav-container {
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  display: flex;
  flex-direction: column;
  gap: 140px;

  @extend %nav-desktop-container;
}
.desktop-nav {
  @extend %nav-desktop-main;
}
.desktop-nav-logout {
  @extend %nav-desktop-logout;
}

.indicator-unread {
  color: white;
  background-color: red;

  font-size: 14px;
  line-height: 14px;
  padding: 4px;

}

.issue-indicator-unread {
  background: #E80B06;
  width: 15px;
  height: 15px;
  border-radius: 100%;
}

</style>
