<template>
  <div class="listing-item-labels-container" :class="'mode-' + mode">
    <span v-for="(label, index) in labels" :key="index">{{ label }}</span>
  </div>
</template>

<script>
export default {
  name: "ListingItemLabels",
  props: {
    labels: Array,
    mode: {
      type: String,
      default: 'light'
    }
  }
}
</script>

<style scoped lang="scss">
.listing-item-labels-container {
  position: relative;
  bottom: 0;
  right: 0;
  top: 10px;
  min-height: 26px;
  transform: translateY(50%);
  text-align: center;

  @extend %listing-item-labels-container;

  &.mode-light {

  }

  &.mode-dark {
    span {
      background: #343742;
      color: #ffffff;
    }
  }

  span {
    display: inline-block;
    background: #ffffff;
    color: #000000;
  }
}
</style>